// app/javascript/controllers/balance_due_controller.js
import { Controller } from "stimulus";
import consumer from "../channels/consumer";

export default class extends Controller {
  static targets = ["partnerSelect", "balanceDue", "dollarsEntry"];

  connect() {
    console.log("BalanceDueController connected");

    this.subscription = consumer.subscriptions.create("BalanceDueChannel", {
      received: this.updateBalanceDue.bind(this),
    });
  }

  disconnect() {
    consumer.subscriptions.remove(this.subscription);
  }

  partnerChanged() {
    console.log("Partner changed", this.partnerSelectTarget);

    let selectElement = this.partnerSelectTarget.querySelector("select");
    console.log(
      "Partner changed",
      this.partnerSelectTarget,
      selectElement.value
    );

    let projectId = this.data.get("projectId");
    if (selectElement) {
      let selectedOptionValue = selectElement.value;
      this.subscription.send({
        partner_id: selectedOptionValue,
        project_id: projectId,
      });
    }
  }

  updateBalanceDue(data) {
    this.balanceDueTarget.textContent = data.balance_due;
    this.dollarsEntryTarget.value = data.balance_due;
  }
}
